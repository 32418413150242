/* eslint-disable no-console,no-undef, camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { InputFieldContainer } from '../SocialFeeds/styles';
import { Square, AddQuizDashboard, OptionCircle, Flex, QuizContainer, OptionContainer, Layout } from '../ArticleQuiz/styles';
import { StyledInput } from '../../containers/Settings/styles';
import { Title, AdventureTitle, TitleContainer, } from '../Article/styles';
import { ArticleButtonsContainer, BackButton } from '../Journeys/styles';
import { AddQuiz } from '../../redux/actions';
import CommonTextArea from '../common/CommonTextArea/CommonTextArea';
import CommonButton from '../common/CommonButton/CommonButton';
import { NewPlusIcon, closeIcon } from '../../utils/icons';

class AddQuizQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonDisable: false,
      quiz: '',
      choices: [
        {
          choice: '',
          is_correct: 0 
        }
      ]  
    }
  }

  componentDidMount() {
    window.scrollTo(0,0);
    this.buttonDisableFunc();
    const { adventureArticle, history } = this.props;
    const arrayOfPath = history.location.pathname.split('/');
    if(!adventureArticle) {
      history.push(`/education/adventure/${arrayOfPath[arrayOfPath.length-4]}/article/${arrayOfPath[arrayOfPath.length-2]}`)
    }
  }

  enterQuestion = (e) => {
    if(this.state.quiz.length < e.target.value.length ) {
      if(e.target.value.trim() !== '') {
        this.setState({ quiz : e.target.value }, () => this.buttonDisableFunc());
      }
    }
    else {
      this.setState({ quiz : e.target.value }, () => this.buttonDisableFunc());
    }
  };

  buttonDisableFunc = () => {
    let dupChoice = [...this.state.choices];
    let prevIndex = dupChoice.findIndex((choice) => choice.is_correct === 1);
    let emptyOption = dupChoice.findIndex((list) => list.choice.trim() === '');
    let emptyQustion = this.state.quiz.trim() === '';

    if(prevIndex > -1 && emptyOption == -1 && !emptyQustion && dupChoice.length >= 2) {
      this.setState({ buttonDisable: false });
    }
    else {
      this.setState({ buttonDisable: true });
    }
  };

  resetState = () => {
    const { history, adventureArticle } = this.props;
    if(adventureArticle){
      history.push(`/education/adventure/${adventureArticle.category_name}/article/${adventureArticle.id}/show-all-quiz`);
    }
  };

  submit = () => {
    const { adventureArticle, addQuiz, history } = this.props;
    const { quiz, choices } = this.state;
    let data = {};
    const stringArray = history.location.pathname.split('/');
    const adventureId = stringArray[stringArray.length - 2];
    data['journey_id'] = adventureArticle ? adventureArticle.id:Number(adventureId);
    data['quizzes'] = [];
    let obj = {};
    obj['quiz'] = quiz;
    obj['choices'] = [...choices];
    data['quizzes'].push(obj);
    addQuiz(data, this.resetState);
    this.setState({ 
      buttonDisable: false,
      quiz: '',
      choices: [
        {
          choice: '',
          is_correct: 0 
        }
      ]  
    });
  };

  addChoice = () => {
    let dupChoice = [...this.state.choices];
    let obj =  {
      choice: '',
      is_correct: 0 
    };
    if(dupChoice.length < 4) {
      dupChoice.push(obj);
    }
    this.setState({ choices: dupChoice }, () => this.buttonDisableFunc());
  }

  enterChoice = (e, index) => {
    let dupChoice = [...this.state.choices];
    if(dupChoice[index].choice.length <  e.target.value.length) {
      if(e.target.value.trim() !== '') {
        dupChoice[index].choice = e.target.value;
        this.setState({ choices: dupChoice }, () => this.buttonDisableFunc());
      }
    }
    else {
      dupChoice[index].choice = e.target.value;
      this.setState({ choices: dupChoice }, () => this.buttonDisableFunc());
    }
  } 

  deleteOption = (index) => {
    let dupChoice = [...this.state.choices];
    if(dupChoice.length > 1) {
      dupChoice.splice(index, 1);
    }
    this.setState({ choices: dupChoice }, () => this.buttonDisableFunc());
  }

  enterCorrectCheckmark = (index) => {
    let dupChoice = [...this.state.choices];
    // dupChoice[index].is_correct === 0 ? dupChoice[index].is_correct = 1: dupChoice[index].is_correct = 0;
    if(dupChoice[index].is_correct === 0){
      dupChoice[index].is_correct = 1
    }
    else{
      dupChoice[index].is_correct = 0
    }
    this.setState({ choices: dupChoice }, () => this.buttonDisableFunc());
  };

  optionChoice = (index) => {
    switch(index) {
    case 0: 
      return `A`;
    case 1:
      return `B`;
    case 2:
      return `C`;
    case 3:
      return `D`;
    }
  }  

  tab = (e) => {
    if(e.key == 'Tab') {
      if(this.state.choices.length < 4) {
        this.addChoice();
      }
      else {
        if(!this.state.buttonDisable) {
          this.submit();
        }
      }
    }
  };

  showChoice = (choices) => (
    choices.map((option, index) => (
      <Flex key={index} noWidth="100%" noCenter={true}>
        <Square isChecked={option.is_correct} onClick={() => this.enterCorrectCheckmark(index)}>{this.optionChoice(index)}</Square>
        <OptionContainer>
          <StyledInput  
            value={option.choice} 
            placeholder="Enter Option" 
            onChange={(e) => this.enterChoice(e, index) }
            onKeyDown={(e) => this.tab(e)}
          />
        </OptionContainer>
        { choices.length > 1 &&
          <OptionCircle marginTop="16px" marginLeft="20px">
            <div className="circle" onClick={() => this.deleteOption(index)}>
              {/* <span className="glyphicon glyphicon-remove" /> */}
              <div style={{width:"16px",height:"16px", display:"flex",alignItems:"center"}}>{closeIcon("white")}</div>
            </div>
          </OptionCircle>
        }
      </Flex>
    ))
  );

  render() {
    const { quiz, choices, buttonDisable } = this.state;
    const { adventureArticle } = this.props;
    return(
      <AddQuizDashboard> 
        <Layout>
          <TitleContainer>
            <Title mainTitle>{adventureArticle && adventureArticle.category_name}</Title>
            <AdventureTitle>{ adventureArticle && adventureArticle.title}</AdventureTitle>
          </TitleContainer>
          <ArticleButtonsContainer right>
            <BackButton onClick={() => this.resetState()}>
              <img src="/public/images/educationAdventureIcons/back-arrow.png" alt="back-arrow" />
              Back
            </BackButton>
          </ArticleButtonsContainer>
        </Layout>
        <Layout>
          <Flex marginBottom="15px" fontSize="16px" marginTop="15px" noCenter={1}>
            <div className="header">
              Question
            </div>
          </Flex>
          <InputFieldContainer>
            <CommonTextArea
              placeholder="Enter Quiz Question"
              rows="3"
              name="description"
              data-gramm_editor="false"
              onChange={(e) => this.enterQuestion(e)}
              value={quiz}
            />
          </InputFieldContainer>
          <QuizContainer>
            <Flex marginTop="15px" fontSize="16px" noCenter={1}>
              <div className="header">
                Options
              </div>
            </Flex>
          </QuizContainer>
          <div>
            { this.showChoice(choices) }
          </div>
          { choices.length < 4 &&
            <Flex noCenter={1}>
              <OptionCircle marginTop="16px" bgColor="#93bf3d" onClick={() => this.addChoice()}>
                <div className="circle">
                  {/* <span className="glyphicon glyphicon-plus"/> */}
                  <NewPlusIcon fill={"white"}/>
                </div>
              </OptionCircle> 
              <div className="addMore" onClick={() => this.addChoice()}> Add more option</div>
            </Flex>
          }
          <Flex marginTop="15px" marginBottom="15px" noCenter={1}>
            {/* <SaveButton disabled={buttonDisable} onClick={this.submit}>
              Submit
            </SaveButton> */}
            <CommonButton
              btnType={"rounded"}
              onClick={this.submit}
              disabled={buttonDisable}
              title={'Submit'}
            />
          </Flex>
        </Layout>
      </AddQuizDashboard>
    )
  }
}

AddQuizQuestion.propTypes = {
  addQuiz: PropTypes.func.isRequired,
  adventureArticle: PropTypes.object,
  history: PropTypes.object
};

const mapStateToProps = (state) => ({
  adventureArticle: state.education.journeyDetails
});

const mapDispatchToProps = (dispatch) => ({
  addQuiz: (data, cb) => dispatch(AddQuiz(data, cb))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddQuizQuestion);